import React from 'react';

// const IconLoader = () => (
//   <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//     <title>Loader Logo</title>
//     <g>
//       <g id="B" transform="translate(11.000000, 5.000000)">
//         <path
//           d="M45.691667,45.15 C48.591667,46.1 50.691667,48.95 50.691667,52.2 C50.691667,57.95 46.691667,61 40.291667,61 L28.541667,61 L28.541667,30.3 L39.291667,30.3 C45.691667,30.3 49.691667,33.15 49.691667,38.65 C49.691667,41.95 47.941667,44.35 45.691667,45.15 Z M33.591667,43.2 L39.241667,43.2 C42.791667,43.2 44.691667,41.85 44.691667,38.95 C44.691667,36.05 42.791667,34.8 39.241667,34.8 L33.591667,34.8 L33.591667,43.2 Z M33.591667,47.5 L33.591667,56.5 L40.191667,56.5 C43.691667,56.5 45.591667,54.75 45.591667,52 C45.591667,49.2 43.691667,47.5 40.191667,47.5 L33.591667,47.5 Z"
//           fill="currentColor"
//         />
//       </g>
//       <path
//         stroke="currentColor"
//         strokeWidth="5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M 50, 5
//                   L 11, 27
//                   L 11, 72
//                   L 50, 95
//                   L 89, 73
//                   L 89, 28 z"
//       />
//     </g>
//   </svg>
// );

const IconLoader = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={491} height={547} viewBox="0 0 491 547" {...props}>
    <path
      d="M 126.250 65.787 L 7 130.451 7 271.105 L 7 411.759 126.635 476.525 C 228.099 531.454, 246.618 541.155, 248.557 540.396 C 249.815 539.903, 303.977 510.700, 368.917 475.500 L 486.989 411.500 486.963 271 L 486.938 130.500 367.176 65.750 C 301.307 30.138, 246.983 1.028, 246.457 1.062 C 245.931 1.096, 191.838 30.222, 126.250 65.787 M 139 87.799 L 32.500 145.511 32.258 271.006 L 32.015 396.500 115.186 441.500 C 160.929 466.250, 209.251 492.393, 222.568 499.596 L 246.780 512.693 354.140 454.563 L 461.500 396.434 461.500 271.116 L 461.500 145.798 354.590 87.899 C 295.789 56.055, 247.189 30.019, 246.590 30.043 C 245.990 30.067, 197.575 56.057, 139 87.799 M 166.369 168.660 C 165.213 171.425, 164.920 175.186, 165.170 184.058 C 165.652 201.189, 163.180 199.909, 196.391 200.234 L 223.500 200.500 223.756 286.727 L 224.011 372.954 227.230 374.619 C 234.288 378.269, 261.485 377.372, 265.499 373.358 C 266.810 372.047, 267 360.993, 267 285.929 L 267 200 294.477 200 L 321.954 200 323.727 197.291 C 325.189 195.057, 325.500 192.355, 325.500 181.895 C 325.500 170.822, 325.258 168.940, 323.595 167.104 C 321.690 165, 321.685 165, 244.794 165 L 167.898 165 166.369 168.660"
      stroke="none"
      fill="#63fbdb"
      fillRule="evenodd"
    />
    <path
      d="M 139 87.799 L 32.500 145.511 32.258 271.006 L 32.015 396.500 115.186 441.500 C 160.929 466.250, 209.251 492.393, 222.568 499.596 L 246.780 512.693 354.140 454.563 L 461.500 396.434 461.500 271.116 L 461.500 145.798 354.590 87.899 C 295.789 56.055, 247.189 30.019, 246.590 30.043 C 245.990 30.067, 197.575 56.057, 139 87.799 M 166.369 168.660 C 165.213 171.425, 164.920 175.186, 165.170 184.058 C 165.652 201.189, 163.180 199.909, 196.391 200.234 L 223.500 200.500 223.756 286.727 L 224.011 372.954 227.230 374.619 C 234.288 378.269, 261.485 377.372, 265.499 373.358 C 266.810 372.047, 267 360.993, 267 285.929 L 267 200 294.477 200 L 321.954 200 323.727 197.291 C 325.189 195.057, 325.500 192.355, 325.500 181.895 C 325.500 170.822, 325.258 168.940, 323.595 167.104 C 321.690 165, 321.685 165, 244.794 165 L 167.898 165 166.369 168.660"
      stroke="none"
      fill="#0c1c2c"
      fillRule="evenodd"
    />
  </svg>
);

export default IconLoader;
